import axios from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import store from '../index';

import { storageSettings } from '../constants';
import { formatMaxSize } from '../helpers';

const isDev = process.env.NODE_ENV === 'development';
const localAPI = 'http://localhost:4000';
const cloudAPI = 'https://api.retro.com.na';
const api  = isDev ? localAPI : cloudAPI;

const fetchData = async (endpoint, params, singleResponse, dispatch, actionType) => {
    const { accessToken } = store.getState().auth.user
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    try {
        const res = await axios.get(api +endpoint, { headers, params });
        const payload = singleResponse
            ? res.data.length
                ? res.data[0]
                : {}
            : res.data;

        dispatch({ type: actionType, payload });
    } catch (err) {
        console.error(`Error fetching data from ${endpoint}:`, err);
        throw err;
    }
};

// get from api and update store
// get events
export const getEvents = (userId) => async (dispatch) => {
    const endpoint = '/events/';
    const payload = {
        userId,
    }
    const actionType = 'GET_EVENTS';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);  
};

// get ticketTypes
export const getTicketTypes = (eventId) => async (dispatch) => {
    const endpoint = '/ticketTypes/';
    const payload = {
        eventId,
    }
    const actionType = 'GET_TICKET_TYPES';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// get ticket categories
export const getTicketCategories = (organizationId) => async (dispatch) => {
    const endpoint = '/ticketCategories/';
    const payload = {
        organizationId,
    }
    const actionType = 'GET_TICKET_CATEGORIES';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// get users
export const getUsers = (organizationId) => async (dispatch) => {
    const endpoint = '/users/';
    const payload = {
        organizationId,
    }
    const actionType = 'GET_USERS';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// get organizations
export const getOrganizations = (userId) => async (dispatch) => {
    const endpoint = '/organizations/';
    const payload = {
        userId,
    }
    const actionType = 'GET_ORGANIZATIONS';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// get ticket transactions
export const getTicketTransactions = (organizationId) => async (dispatch) => {
    const endpoint = '/ticketTransactions/';
    const payload = {
        organizationId,
    }
    const actionType = 'GET_TICKET_TRANSACTIONS';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// get promo codes
export const getPromoCodes = (organizationId) => async (dispatch) => {
    const endpoint = '/promoCodes/';
    const payload = {
        organizationId,
    }
    const actionType = 'GET_PROMO_CODES';
    const singleResponse = 0
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// update api from forms
// create event
export const createEvent = (organization, event) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    const endpoint = '/events/';
    const payload = {
        ...event,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_EVENT';
    toast.info('Adding event...')
    try {
        const { data } = await axios.post(api + endpoint, payload, { headers });
        toast.success('Event added successfully');
        dispatch({ type: actionType, payload: data.payload });        
    } catch (error) {
        toast.error('Event already exists');        
    }
}

// create ticket category
export const createTicketCategory = (ticketCategory) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketCategories/';
    const payload = {
        ...ticketCategory,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_TICKET_CATEGORY';
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('Ticket category created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('Ticket category already exists');        
    }
}

// create ticketTypes
export const createTicketType = (ticket) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTypes/';
    const payload = {
        ...ticket,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_TICKET_TYPE';
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('TicketType created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('TicketType already exists');        
    }
}

// create user
export const createUser = (user) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/users/';
    const payload = {
        ...user,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_USER';
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('User created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('User already exists');        
    }
}

// create organization
export const createOrganization = (organization) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/organizations/';
    const payload = {
        ...organization,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_ORGANIZATION';
    toast.info('Adding organizer...')
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('Organization created successfully');
        dispatch({ type: actionType, payload: data.payload });        
    } catch (error) {
        toast.error('Organization already exists');        
    }
}

// create ticket transaction
export const createTicketTransaction = (ticketTransaction) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTransactions/';
    const payload = {
        ...ticketTransaction,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_TICKET_TRANSACTION';
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('Ticket transaction created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('Ticket transaction already exists');        
    }
}

// create promo code
export const createPromoCode = (promoCode) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/promoCodes/';
    const payload = {
        ...promoCode,
        created: dayjs().unix(),
    }
    const actionType = 'CREATE_PROMO_CODE';
    try {
        const { data } = await axios.post(api +endpoint, payload, { headers });
        toast.success('Promo code created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('Promo code already exists');        
    }
}

// update to db
export const updateEvent = (event) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/events/';
    const payload = {
        ...event,
    }
    const actionType = 'UPDATE_EVENT';
    const toastId = uuidv4();
    toast.info('Updating event...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('Event updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        console.log(error);
        toast.error('Error updating event');
        toast.dismiss(toastId);
    }
}
export const updateTicketType = (ticket) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTypes/';
    const payload = {
        ...ticket,
    }
    const actionType = 'UPDATE_TICKET_TYPE';
    const toastId = uuidv4();
    toast.info('Updating ticket...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('TicketType updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating ticket');
    }
}
export const updateTicketCategory = (ticketCategory) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketCategories/';
    const payload = {
        ...ticketCategory,
    }
    const actionType = 'UPDATE_TICKET_CATEGORY';
    const toastId = uuidv4();
    toast.info('Updating ticket category...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('Ticket category updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating ticket category');
    }
}
export const updateUser = (user) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/users/';
    const payload = {
        ...user,
    }
    const actionType = 'UPDATE_USER';
    const toastId = uuidv4();
    toast.info('Updating user...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('User updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating user');
    }
}
export const updateOrganization = (organization) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/organizations/';
    const payload = {
        ...organization,
    }
    const actionType = 'UPDATE_ORGANIZATION';
    const toastId = uuidv4();
    toast.info('Updating organization...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('Organization updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating organization');
    }
}
export const updateTicketTransaction = (ticketTransaction) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTransactions/';
    const payload = {
        ...ticketTransaction,
    }
    const actionType = 'UPDATE_TICKET_TRANSACTION';
    const toastId = uuidv4();
    toast.info('Updating ticket transaction...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('Ticket transaction updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating ticket transaction');
    }
}
export const updatePromoCode = (promoCode) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/promoCodes/';
    const payload = {
        ...promoCode,
    }
    const actionType = 'UPDATE_PROMO_CODE';
    const toastId = uuidv4();
    toast.info('Updating promo code...', { toastId, autoClose: false });
    try {
        await axios.patch(api + endpoint, payload, { headers });
        toast.success('Promo code updated successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload });
    } catch (error) {
        toast.dismiss(toastId);
        toast.error('Error updating promo code');
    }
}



export const updateEventStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_EVENT_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}

export const updateTicketCategoryStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_TICKET_CATEGORY_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}

export const updateTicketTypeStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_TICKET_TYPE_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}

export const updateUserStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_USER_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}
export const updateOrganizationStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_ORGANIZATION_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}

export const updateTicketTransactionStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_TICKET_TRANSACTION_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}

export const updatePromoCodeStore = (key, value) => async (dispatch) => {
    const actionType = 'UPDATE_PROMO_CODE_STORE';
    dispatch({ type: actionType, payload: { key, value } });
}


// reset
export const resetEvent = () => async (dispatch) => {
    const actionType = 'RESET_EVENT';
    dispatch({ type: actionType });
}
export const resetTicketType = () => async (dispatch) => {
    const actionType = 'RESET_TICKET_TYPE';
    dispatch({ type: actionType });
}
export const resetTicketCategory = () => async (dispatch) => {
    const actionType = 'RESET_TICKET_CATEGORY';
    dispatch({ type: actionType });
}
export const resetUser = () => async (dispatch) => {
    const actionType = 'RESET_USER';
    dispatch({ type: actionType });
}
export const resetOrganization= () => async (dispatch) => {
    const actionType = 'RESET_ORGANIZATION';
    dispatch({ type: actionType });
}
export const resetTicketTransaction = () => async (dispatch) => {
    const actionType = 'RESET_TICKET_TRANSACTION';
    dispatch({ type: actionType });
}
export const resetPromoCode = () => async (dispatch) => {
    const actionType = 'RESET_PROMO_CODE';
    dispatch({ type: actionType });
}


// set for update/edit
export const setEvent = (event) => async (dispatch) => {
    const actionType = 'SET_EVENT';
    dispatch({ type: actionType, payload: event });
}
export const setTicketType = (ticket) => async (dispatch) => {
    const actionType = 'SET_TICKET_TYPE';
    dispatch({ type: actionType, payload: ticket });
}
export const setTicketCategory = (ticketCategory) => async (dispatch) => {
    const actionType = 'SET_TICKET_CATEGORY';
    dispatch({ type: actionType, payload: ticketCategory });
}
export const setUser = (user) => async (dispatch) => {
    const actionType = 'SET_USER';
    dispatch({ type: actionType, payload: user });
}
export const setOrganization = (organization) => async (dispatch) => {
    const actionType = 'SET_ORGANIZATION';
    dispatch({ type: actionType, payload: organization });
}
export const setTicketTransaction = (ticketTransaction) => async (dispatch) => {
    const actionType = 'SET_TICKET_TRANSACTION';
    dispatch({ type: actionType, payload: ticketTransaction });
}
export const setPromoCode = (promoCode) => async (dispatch) => {
    const actionType = 'SET_PROMO_CODE';
    dispatch({ type: actionType, payload: promoCode });
}


// delete
export const deleteEvent = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/events/';
    const actionType = 'DELETE_EVENT';
    const data = { id }
    // create toastId to dismiss toast using uuid v4
    const toastId = uuidv4();
    console.log(headers);
    toast.info('Deleting event...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('Event deleted successfully');
        // dismiss toast using toastId
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting event');    
        toast.dismiss(toastId);  
    }
}
export const deleteTicketType = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTypes/';
    const actionType = 'DELETE_TICKET_TYPE';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting ticket...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('TicketType deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting ticket');    
        toast.dismiss(toastId);  
    }
}
export const deleteTicketCategory = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketCategories/';
    const actionType = 'DELETE_TICKET_CATEGORY';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting ticket category...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('Ticket category deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting ticket category');    
        toast.dismiss(toastId);  
    }
}
export const deleteUser = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/users/';
    const actionType = 'DELETE_USER';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting user...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('User deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting user');    
        toast.dismiss(toastId);  
    }
}
export const deleteOrganization = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/organizations/';
    const actionType = 'DELETE_ORGANIZATION';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting organization...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('Organization deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting organization');    
        toast.dismiss(toastId);  
    }
}
export const deleteTicketTransaction = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/ticketTransactions/';
    const actionType = 'DELETE_TICKET_TRANSACTION';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting ticket transaction...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('Ticket transaction deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting ticket transaction');    
        toast.dismiss(toastId);  
    }
}
export const deletePromoCode = (id) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user;
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const endpoint = '/promoCodes/';
    const actionType = 'DELETE_PROMO_CODE';
    const data = {
        id,
    }
    const toastId = uuidv4();
    toast.info('Deleting promo code...', { toastId, autoClose: false });
    try {
        await axios.delete(api + endpoint, { data, headers });;
        toast.success('Promo code deleted successfully');
        toast.dismiss(toastId);
        dispatch({ type: actionType, payload: id });        
    } catch (error) {
        toast.error('Error deleting promo code');    
        toast.dismiss(toastId);  
    }
}

export const updateUploadProgress = (name, progress) => async (dispatch) => {
    const actionType = 'UPDATE_UPLOAD_PROGRESS';
    dispatch({ type: actionType, payload: { name, progress } });
};

export const uploadNewStorage = (name, file) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    // check and restrict file types
    const storageObject = storageSettings.find((a) => a.indexName === name);
    const { fileTypes } = storageObject;
    const { type } = file;
    if (!fileTypes.includes(type)) {
        toast.error(`File type not supported. Supported file types are: ${fileTypes.join(", ")}`);
        return;
    }

    // check and restrict file size
    const { size } = file;
    const { maxSize } = storageObject;
    if (size > maxSize) {
        toast.error(`File size too large. Maximum file size is: ${formatMaxSize(maxSize)}`);
        return;
    }

    const toastId = dayjs().unix();
    try { 
      const formData = new FormData();
      formData.append('file', file);
  
      const { data } = await axios.post(cloudAPI +"/upload", formData, {
        headers,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / total);
          if (progress <= 100) {
            dispatch( updateUploadProgress(name, progress) );
          }
        }
      });
      
      const { filename } = data;
      const { event } = store.getState().events;
      const { appearance } = event;

      dispatch( updateEventStore('appearance', { ...appearance, [name]: filename }) );
      dispatch( updateUploadProgress(name, null) );
  
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      const message = error.response?.data?.message?.message || error.response?.data?.message;
      toast.warn(message);
    }
  };

export const deleteStorage = (filename) => async (dispatch) => {
    const { accessToken } = store.getState().auth.user
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    if (!filename) {
        console.log('No file selected');
        return;
    }

    const toastId = dayjs().unix();
    try {
      await axios.delete(cloudAPI +"/upload", { data: { filename }, headers });
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      const message = error.response?.data?.message?.message || error.response?.data?.message;
      toast.warn(message);
    }
}