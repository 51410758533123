import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { getOrganizations, getEvents } from "./store/actions/eventsAction";

import Layout from "./pages/Layout";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import CreateEvent from "./pages/events/CreateEvent";
import BuyTicket from "./pages/events/BuyTicket";

import "./assets/css/Colors.css"
import CreateOrganizer from "./pages/events/CreateOrganizer";
import Events from "./pages/Events";
import EventEdit from "./components/events/create-event/EditEvent";

function App(props) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { organizationsFetchStatus } = useSelector((state) => state.events);
  const { getOrganizations, getEvents } = props;

  // make a get request to get organizations when isAuthenticated changes from 0 to 1
  useEffect(() => {
    if (Number(isAuthenticated) && !Number(organizationsFetchStatus) ) {
      getOrganizations(user.id);
      getEvents(user.id);
    }
  }, [isAuthenticated, user, organizationsFetchStatus, getOrganizations, getEvents]);

  const routerContent = !Number(isAuthenticated) ? (
    <ChakraProvider>
      <ToastContainer position="bottom-left"  />
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="*" element={ <Navigate to="/sign-in/" replace /> } />
      </Routes>
    </ChakraProvider>
  ) : (
    <ChakraProvider>
      <Layout>
        <ToastContainer position="bottom-left" />
        <Routes>
          <Route path="/sign-in" element={<Navigate to="/" replace />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/events/add-event/" element={<CreateEvent />} />
          <Route path="/events/add-organizer/" element={<CreateOrganizer />} />
          <Route path="/events/buy-ticket/" element={<BuyTicket />} />
          <Route path="/events/all-events/" element={<Events />} />
          <Route path="/events/edit-event/:id/" element={<EventEdit />} />

        </Routes>
      </Layout>
    </ChakraProvider>
  );

  return (
    <Router>
      {routerContent}
    </Router>
  );
}

export default connect(null, {
  getOrganizations,
  getEvents,
})(App);
