import dayjs from "dayjs";

// Object to store information about events
export const event = Object.freeze({
  userId: "",
  organizationId: "",
  details: {
    name: "",
    description: "",
    eventUrl: "",
    basic: {
      expectedAttendance: "",
      country: "",
      city: "",
      onlineStreaming: 0,
      price: {
        currency: "NAD",
        symbol: "N$",
        averageTicketPrice: "",
      },
    },
    venue: {
      name: "",
      address: "",
    },  
    category: {
      type: "",
      subType: "",
    },
    information: [ //set additional information for the event
      {
        name: "Age Requirement",
        value: "",
      },
      {
        name: "Parking",
        value: "",
      },
      {
        name: "Prohibited Items",
        value: "",
      },
      {
        name: "Dress Code",
        value: "",
      },
    ]
  },
  
  appearance: 
    {
      logo: "",
      headerImage: "",
      headerVideo: "",
      appleWalletPassImage: "",
    },
  donations: {},
  ticketTypes: [],
  date: {
    // start of the minute
    start: dayjs().startOf("hour").unix(),
    end: dayjs().startOf("hour").unix(),
    tz: "Africa/Windhoek",
  },
  paymentProviders: {
    card: 1,
    payToday: 1,
    payPulse: 1,
    bankWindhoek: 1,
  },
  settings: {
    ticketRegistration: 0,
    forceTicketAssignment: 0,
    requireSignUp: 0,
    maxItemsPerOrder: 10,
    hideEvent: 0,
  },
  
  ageDemographic: "",
  eventFrequency: "",
  status: 0, // 0 - unpublished, 1 - published, 2 - archived
});

// Object to store information about ticket pricing phases
export const ticketCategory = Object.freeze({
  eventId: "",
  name: "",
  description: "",
});

// Object to store information about ticket types
export const ticketType = Object.freeze({
  eventId: "",
  name: "",
  description: "",
  quantityAvailable: "",
  price: "",
  customerFees: {
    booking: 0,
    Gateway: 0,
  },
  advanced: {
    setStartAndEndDate: 0,
    date: {
      start: dayjs().startOf("hour").unix(),
      end: dayjs().startOf("hour").unix(),
    },
    hidePriceTillSale: 0,
    minimum: 0,
    maximum: 10,
  },
});

// Object to store user information
export const user = Object.freeze({
  name: "",
  lastname: "",
  email: "",
  mobile: "",
  password: "",
  identification: {
    idNumber: "",
    passportNumber: "",
    passportCountry: "",
  },
  address: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  },
  emergencyInformation: {
    medicalAidProvider: "",
    medicalAidNumber: "",
    emergencyContactName: "",
    emergencyContactMobile: "",
    emergencyContactRelationShip: "",
    allergies: "",
    medicalConditions: "",
  }
});

export const organization = Object.freeze({
  userId: "",
  name: "",
  urlName: "",
  registeredName: "",
  companyRegistrationNumber: "",
  vatRegistration: "",
  identityDocument: "",
  proofOfAddress: "",
  address: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  },
  bankAccount: {
    country: "",
    bankName: "",
    accountNumber: "",
    branchCode: "",
    swiftCode: ""
  }
});

// Object to store information about user transactions
export const ticketTransaction = Object.freeze({
  transactionId: "",
  userId: "",
  eventId: "",
  ticketTypeId: "",
  phaseId: "",
  quantity: "",
  totalAmount: "",
  created: ""
});

// Object to store promo codes
export const promoCode = Object.freeze({
  promoCodeId: "",
  eventId: "",
  code: "",
  discount: "",
  maxUses: "",
  expirationDate: ""
});

export const expectedAttendanceList = Object.freeze([
  "100 - 500",
  "500 - 1,000",
  "1,000 - 2,000",
  "2,000 - 3,000",
  "3,000 - 5,000",
  "5,000 - 10,000",
  "10,000 +"
]);


export const eventFrequencyList = Object.freeze([
  "Once",
  "Weekly",
  "Monthly",
  "Quarterly",
  "Yearly"
]);

export const ageDemographicList = Object.freeze([
  "All Ages",
  "Under 18",
  "Over 18",
  "18-25",
  "26-35",
  "35-50",
  "50+"
]);

export const paymentProviders = Object.freeze([
  {
    name: "Credit/Debit Card",
    value: "card",
    interestRate: 2.5,
    minimumFee: 2.5,
    optional: 0,
  },
  {
    name: "PayToday",
    value: "payToday",
    interestRate: 2.5,
    minimumFee: 2.5,
    optional: 1,
  },
  {
    name: "PayPulse",
    value: "payPulse",
    interestRate: 3.0,
    minimumFee: 2.5,
    optional: 1,
  },
  {
    name: "Bank Windhoek",
    value: "bankWindhoek",
    interestRate: 2,
    minimumFee: 2.5,
    optional: 1,
  },
]);

export const storageSettings = [
  {
    indexName: "logo",
    name: "Event Logo",
    maxSize: 1024 * 1024 * 0.390625, // 400KB 
    dimensions: [160, 48],
    fileTypes: ["image/jpg","image/jpeg", "image/png"]
  },
  {
    indexName: "headerImage",
    name: "Header Image",
    maxSize: 1024 * 1024 * 1.5, // 1.5MB
    dimensions: [1920, 1080],
    fileTypes: ["image/jpg", "image/jpeg", "image/png"]
  },
  {
    indexName: "headerVideo",
    name: "Header Video",
    maxSize: 1024 * 1024 * 15, // 15MB
    dimensions: [1920, 1080],
    fileTypes: ["video/mp4"]
  },
  {
    indexName: "appleWalletPassImage",
    name: "Apple Wallet Pass Image",
    maxSize: 1024 * 1024 * 0.75, // 750KB
    dimensions: [375, 98],
    fileTypes: ["image/jpg", "image/jpeg", "image/png"]
  }
];

export const modules = {
  toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['clean']
  ]
};

export const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export const categories = Object.freeze([
  "Business",
  "School",
  "Lifestyle",
  "Nightlife",
  "Festival",
  "Comedy",
  "Sports",
  "Performance",
  "Music",
  "Food",
  "Dance",
  "Art",
  "Film",
  "Fashion",
  "Health",
  "Science",
  "Technology",
  "Religion",
  "Charity",
  "Community",
  "Other"
]);