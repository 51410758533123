import { VStack, Text, Divider, Box, Flex, Checkbox } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";

import { updateEventStore } from "../../../../store/actions/eventsAction";
import { paymentProviders } from "../../../../store/constants";


const FeesOverview = (props) => {

  const { event } = useSelector((state) => state.events);
  const { details } = event;
  const { basic } = details;
  const { price } = basic;

  return (
    <Box>
      <VStack spacing="5px" w="100%">
        <Box w="100%">
          <Text fontWeight="bold" textAlign="left">Fee Structure</Text>
          <Text textAlign="left">One simple fee which covers everything.</Text>
        </Box>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontWeight="bold" textAlign="left">Organizer</Text>
          <Text textAlign="right">Camo Day Club</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text textAlign="left">email</Text>
          <Text textAlign="right">tikhoe@retro.com.na</Text>
        </Flex>
        <Divider m="10px 0" />
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontWeight="bold" textAlign="left">Fee per ticket</Text>
          <Text textAlign="right">5.0%</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text textAlign="left">excl. payment processing</Text>
          <Text textAlign="right">min. { price.symbol }5.00 - max { price.symbol }100.00</Text>
        </Flex>
        <Divider m="10px 0" />
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontWeight="bold" textAlign="left">Free and complimentary tickets</Text>
          <Text textAlign="right">{ price.symbol }5.00</Text>
        </Flex>
        <Divider m="10px 0" />
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontWeight="bold" textAlign="left">Donations</Text>
          <Text textAlign="right">0.0%</Text>
        </Flex>
        <Text textAlign="left" w="100%">One simple fee which covers everything.</Text>
        <Box w="100%" bg="#ececec" p="15px 10px" m="30px 0 5px 0">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text fontWeight="bold" textAlign="left">PAYMENT PROCESSING FEES</Text>
            <Text textAlign="right">per payment</Text>
          </Flex>
        </Box>
        {
          paymentProviders.map((a, i) => (
            <Box key={i} w="100%">
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text fontWeight="bold" textAlign="left">{a.name}</Text>
                <Text fontWeight="bold" textAlign="right">{a.interestRate}%</Text>
              </Flex>
              <Text textAlign="right" w="100%">min. { price.symbol }{a.minimumFee.toFixed(2)}</Text>
              { 
                Number(a.optional)
                ? <Box w="100%" bg="#ececec" p="10px">
                    <Checkbox isChecked={event.paymentProviders[a.value] || 0 } onChange={ (e) => props.updateEventStore("paymentProviders", { ...event.paymentProviders, [a.value]:  e.target.checked ? 1 : 0 } )}>Allow customers to pay with {a.name}</Checkbox>
                  </Box>
                : null
              }
              {/* hide on last index */}
              { i !== paymentProviders.length - 1 && <Divider m="10px 0" /> }
            </Box>
          ))
        }
        

      </VStack>
    </Box>
  );
};

export default connect(null, {
  updateEventStore,
})(FeesOverview);
