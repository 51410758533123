import React, { useState, useRef } from 'react';
import { Box, FormControl, FormLabel, HStack, Input, Text, Flex, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, Stack, Button, Link } from "@chakra-ui/react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { BsThreeDotsVertical, BsCloudArrowUp } from 'react-icons/bs';
import { RiFileShredLine } from 'react-icons/ri';
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateEventStore, uploadNewStorage, deleteStorage } from "../../../../../store/actions/eventsAction";
// helpers
import { formatMaxSize } from "../../../../../store/helpers";

const StorageUpload = (props) => {
    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const { a } = props;
    const { event, uploads } = useSelector((state) => state.events);
    const { appearance } = event;
      
    return (
        <FormControl mt="15px">
            <FormLabel>{a.name}</FormLabel>
            <HStack justifyContent="space-between">
                <HStack spacing="20px">                                                                    
                    {
                        appearance[a.indexName].length
                        ?   <Box 
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w="200px"
                                h="100px"
                                border="1px solid #ececec"
                                borderRadius="4px"
                                overflow="hidden"
                            >
                                {
                                    a.fileTypes.includes("image/jpeg")
                                    ?   <img src={`https://api.retro.com.na/storage/${appearance[a.indexName]}`} alt={a.name} />
                                    : <video src={`https://api.retro.com.na/storage/${appearance[a.indexName]}`}></video>
                                }
                            </Box>
                        :   <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w="200px"
                                h="100px"
                                border="1px solid #ececec"
                                borderRadius="5px"
                            >
                                <button
                                    style={{
                                        // Style the button to look like a box
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        // ... other styles
                                    }}
                                    onClick={() => document.getElementById(a.indexName).click()}
                                >
                                    <IoCloudUploadOutline size={30} /> <Text marginLeft="10px">Upload</Text>
                                </button>
                            </Box>
                        
                    }
                    <Box>
                        <Input
                            type="file"
                            id={a.indexName}
                            name={a.indexName}
                            // restrict file types
                            accept={a.fileTypes.join(", ")}
                            onChange={(e) => {
                                dispatch(uploadNewStorage(a.indexName, e.target.files[0]));
                                if (fileInputRef.current) {
                                    setTimeout(() => {
                                        fileInputRef.current.value = null;
                                    }, 2000);
                                }
                            }}
                            style={{ display: "none" }}
                            ref={fileInputRef}
                        />
                        <Text>Dimensions: {a.dimensions.map((b,i) => b.toString()).join(" x ") } </Text>
                        <Text>File types: { a.fileTypes.map(fileType => fileType.split("/")[1].toLowerCase()).join(", ") }</Text>
                        <Text>Max Size: { formatMaxSize(a.maxSize) }</Text>
                        {
                            appearance[a.indexName].length
                                ?   <Link  color='teal.500' href={`https://api.retro.com.na/storage/${appearance[a.indexName]}`} isExternal>{`View ${a.name}`}</Link>
                                :   null
                        }
                        {
                            uploads[a.indexName] != null ?
                            <Text>{ uploads[a.indexName] }%</Text> : null
                        }
                    </Box>
                </HStack>

                <Flex justifyContent="center" mt={4}>
                <Popover placement="bottom-end" >
                    <PopoverTrigger>
                        <IconButton
                            aria-label="More server options"
                            icon={<BsThreeDotsVertical />}
                            variant="solid"
                            w="fit-content"
                        />
                    </PopoverTrigger>
                    <PopoverContent 
                        w="fit-content" 
                        _focus={{ boxShadow: 'none' }}
                        zIndex={99}
                    >
                        <PopoverArrow />
                        <PopoverBody>
                            <Stack>
                                <Button
                                    w="194px"
                                    variant="ghost"
                                    rightIcon={<BsCloudArrowUp />}
                                    justifyContent="space-between"
                                    fontWeight="normal"
                                    fontSize="sm"
                                    onClick={ () => {
                                        dispatch(deleteStorage(appearance[a.indexName]));
                                        dispatch(updateEventStore("appearance", { ...appearance, [a.indexName]: "" }));
                                        document.getElementById(a.indexName).click();
                                    }}
                                    >
                                    Upload new logo
                                </Button>
                                <Button
                                    w="194px"
                                    variant="ghost"
                                    rightIcon={<RiFileShredLine />}
                                    justifyContent="space-between"
                                    fontWeight="normal"
                                    colorScheme="red"
                                    fontSize="sm"
                                    onClick={() => {
                                        dispatch(updateEventStore("appearance", { ...appearance, [a.indexName]: "" }));
                                        dispatch(deleteStorage(appearance[a.indexName]))
                                    }}
                                    >
                                    Delete Current logo
                                </Button>
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                </Flex>

            </HStack>
        </FormControl>
    );
}

export default StorageUpload;