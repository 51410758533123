// format time in seconds to HH:MM
export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toFixed(0);
  const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
  const formattedTime = `${hours} hours${minutes>0?", " + minutes + "minutes":""}`;
  return formattedTime;
};

export const formatNumber = (number) => {
  return number.toLocaleString();
};

export const formatMaxSize = (maxSize) => {
  if (maxSize < 1048576) {
    return `${(maxSize / 1024).toFixed(2) } KB`;
  } else {
    // Round to two decimal places for MB values
    return `${(maxSize / 1048576).toFixed(2)} MB`;
  }
}
