import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';
import { connect } from "react-redux";
import { signIn } from "../store/actions/authAction";

const Dashboard = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = props;

  return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          width="400px"
          p={8}
          borderWidth={1}
          borderRadius={8}
          boxShadow="lg"
        >
          <FormControl>
            <FormLabel>Email address</FormLabel>
            <Input type="email" value={email} placeholder="Enter your email" mb={4} onChange={ (e) => setEmail(e.target.value) } />
          </FormControl>

          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input type="password" value={password} placeholder="Enter your password" mb={6} onChange={ (e) => setPassword(e.target.value) } />
          </FormControl>

          <Button colorScheme="teal" onClick={ () => signIn(email, password) }>
            Sign In
          </Button>
        </Box>
      </Box>
  );
};
export default connect(null, { signIn })(Dashboard);