import { Input, FormControl, FormLabel, Checkbox, Select, Box, Text } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import { updateEventStore, createEvent } from "../../../../store/actions/eventsAction";

import { expectedAttendanceList } from "../../../../store/constants";

const CreateEvent = (props) => {

  const { event, organizations } = useSelector((state) => state.events);
  const organization = organizations.find((a) => a.id === event.organizationId);

  const { updateEventStore } = props;

  const { details } = event;
  // remove make lowercase, remove all special characters except hyphen
  const eventName = details.name.length
  ? details.name.replace(/\s+/g, '-').toLowerCase().replace(/[^a-zA-Z0-9-]/g, '')
  : '';

  return (
    <>  
      {
        event.organizationId.length
        ? <Box w="100%" mb="20px">
            <Text color="var(--blue)" textAlign="left">https://retro.com.na/{ organization.urlName }{ eventName.length ? "/" + eventName : '' }</Text>
          </Box>
        : null
      }

        <FormControl mb="10px">
          <FormLabel mb="2px">Event Name</FormLabel>
          <Input type="text" name="name" value={details.name} onChange={ (e) => updateEventStore("details", { ...event.details, name: e.target.value}) } />
        </FormControl>

        <FormControl mb="10px">
          <FormLabel  mb="2px">Expected Attendance</FormLabel>
          <Select placeholder='Select option' value={details.basic.expectedAttendance} onChange={ (e) => updateEventStore("details", { ...event.details, basic: { ...details.basic, expectedAttendance: e.target.value }} ) }>
            {expectedAttendanceList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </FormControl>
        
        <FormControl mb="10px">
          <FormLabel  mb="2px">Country</FormLabel>
          <Input type="text" value={details.basic.country} onChange={ (e) => updateEventStore("details", { ...event.details, basic: { ...details.basic, country:  e.target.value }} ) } />
        </FormControl>
        <FormControl mb="10px">
          <FormLabel  mb="2px">City</FormLabel>
          <Input type="text" value={details.basic.city} onChange={ (e) => updateEventStore("details", { ...event.details, basic: { ...details.basic, city:  e.target.value }} ) } />
        </FormControl>

        <FormControl mb="10px">
          <FormLabel  mb="2px">Online Streaming</FormLabel>
          <Checkbox isChecked={details.basic.onlineStreaming} onChange={(e) => updateEventStore("details", { ...event.details, basic: { ...details.basic, onlineStreaming:  e.target.checked ? 1 : 0 }} )}>Online Streaming</Checkbox>
        </FormControl>
    </>
  );
};

export default connect(null, {
  updateEventStore,
  createEvent,
})(CreateEvent);
