import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Button, Container, FormControl, FormLabel, HStack, Input, Text } from "@chakra-ui/react";
import { IoStorefrontOutline } from 'react-icons/io5'

import _ from "lodash";

import { createOrganization, updateOrganizationStore, updateEventStore, resetOrganization } from "../../store/actions/eventsAction";

const CreateOrganizer = props => {
    const [urlNameError, setUrlNameError] = useState("");

    const { organization } = useSelector((state) => state.events);
    const { user } = useSelector((state) => state.auth);
    const { createOrganization, updateOrganizationStore, updateEventStore, resetOrganization } = props;

    // useEffect
    useEffect(() => {
        resetOrganization()
    }, [resetOrganization]);

    return (
        <Container maxWidth="500px">
        {
        organization.id == null
        ?   <>
                <FormControl mb="10px">
                <FormLabel mb="5px">Organization Name</FormLabel>
                <Input type="text" name="name" value={organization.name} onChange={ (e) => updateOrganizationStore(e.target.name, e.target.value) } />
                </FormControl>
                <FormControl mb="10px">
                <FormLabel mb="5px">URL Name</FormLabel>
                <Input
                    type="text"
                    name="urlName"
                    value={organization.urlName}
                    onChange={(e) => {
                    const inputValue = e.target.value.trim(); // Trim leading and trailing spaces
                    const isValid = /^[a-z0-9-]*$/.test(inputValue);
                    if (isValid) {
                        updateOrganizationStore(e.target.name, inputValue);
                        setUrlNameError("");
                    } else {
                        setUrlNameError("Only lowercase letters, numbers, and hyphens are allowed.");
                    }
                    }}
                />
                <Text fontSize="xs" color="gray.500">
                    https://retro.com/<strong>{organization.urlName}</strong>
                </Text>
                {
                    urlNameError && (
                    <Text fontSize="xs" color="red.500">
                        {urlNameError}
                    </Text>
                )}
                </FormControl>
                
                {/* submit */}
                <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={() => createOrganization({ ...organization, userId: user.id }) }
                >Add Organizer</Button>
            </>
        :   <Box textAlign="center" py={10} px={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <IoStorefrontOutline size={'3em'} />
                <Text fontSize="18px" mt={3} mb={2}>
                    <strong>{organization.name}</strong> was added successfully.
                </Text>
                <HStack spacing={3} mt="20px" width="100%" justifyContent="center">
                    <NavLink to="/" onClick={() => resetOrganization()}>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                        >Dashboard</Button>
                    </NavLink>
                    <NavLink to="/events/add-event/" onClick={() => { updateEventStore("userId", user.id); updateEventStore("organizationId", organization.id); }}>
                        <Button
                            colorScheme="teal"
                            color="white"
                            variant="solid"
                        >Add a new Event</Button>
                    </NavLink>
                </HStack>
            </Box>
        }
        </Container>

    );
};

export default connect(null, {
    updateOrganizationStore,
    updateEventStore,
    createOrganization,
    resetOrganization,
})(CreateOrganizer);



