import React from 'react';
import dayjs from "dayjs";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { deleteEvent } from '../store/actions/eventsAction';

const Events = () => {
    const dispatch = useDispatch();
    const { organizations, events } = useSelector((state) => state.events);

  return (
    <Box w="100%">
        <TableContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Event Name</Th>
                        <Th>Organizer</Th>
                        <Th>Start Date</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        events.map( (a,i) => 
                            <Tr key={i}>
                                <Td>{a.details.name}</Td>
                                <Td>{organizations.find( o => o.id === a.organizationId).name}</Td>
                                <Td>
                                    Start: { dayjs.unix(a.date.start).format("HH:mm D MMM YYYY") }<br />
                                    End: { dayjs.unix(a.date.end).format("HH:mm D MMM YYYY") }
                                </Td>
                                <Td>
                                    <NavLink to={"/events/edit-event/" + a.id + "/"}>
                                        <Text color="blue.500" mr="10px">Edit</Text>
                                    </NavLink>

                                    <Text color="blue.500" mr="10px" onClick={ () => dispatch(deleteEvent(a.id)) }>Delete</Text>

                                </Td>
                            </Tr>
                        )
                    }
                </Tbody>

            </Table>
        </TableContainer>
    </Box>
  )
}

export default Events;