import { Box, Button, Divider, HStack } from "@chakra-ui/react";
import Currency from "./createEvent/Currency";
import DatesInfo from "./createEvent/DatesInfo";

const Step2 = ({ onPrev, onNext }) => {
    return (
        <>
            <Box mb="15px">
                <Currency />
                <Divider m="10px 0" />
                <DatesInfo />
            </Box>
            <HStack spacing="15px">
                <Button onClick={onPrev}>Previous</Button>
                <Button onClick={onNext}>Next</Button>
            </HStack>
        </>
    );
}

export default Step2;
