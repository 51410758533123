import { Button, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Divider, Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { updateEventStore } from "../../../../store/actions/eventsAction";
import { Switch } from '@chakra-ui/react'

// actions
import { updateEvent } from "../../../../store/actions/eventsAction";

const EventSettings = () => {
    const dispatch = useDispatch();
    const { event } = useSelector((state) => state.events);
    const { settings } = event;

    return (
        <AccordionItem overflow="visible">
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                    <Heading as="h3" size="md">Event Settings</Heading>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} overflow="visible">
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='ticketRegistration' mb='10px'>
                        Ticket Registration
                    </FormLabel>
                    <Switch
                        id='ticketRegistration'
                        onChange={(e) => dispatch(updateEventStore("settings", { ...settings, ticketRegistration: e.target.checked }))}
                        isChecked={Number(settings.ticketRegistration)} 
                        />
                </FormControl>

                {
                    settings.ticketRegistration
                    ?   <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='forceTicketAssignment' mb='10px'>
                            Force customers to assign tickets during purchase
                            </FormLabel>
                            <Switch
                                id='forceTicketAssignment'
                                onChange={ (e) => dispatch(updateEventStore("settings", { ...settings, forceTicketAssignment: e.target.checked })) }
                                isChecked={Number(settings.forceTicketAssignment)} 
                            />
                        </FormControl>
                    : null
                }

                <Divider m="10px 0" />

                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='requireSignUp' mb='10px'>
                    Require recipients to sign up before downloading tickets.
                    </FormLabel>
                    <Switch
                        id='requireSignUp'
                        onChange={ (e) => dispatch(updateEventStore("settings", { ...settings, requireSignUp: e.target.checked })) }
                        isChecked={Number(settings.requireSignUp)} 

                    />
                </FormControl>

                <Divider m="10px 0" />

                <FormControl mb="10px">
                    <FormLabel>Max items per order</FormLabel>
                    <Input 
                        type="number"
                        value={settings.maxItemsPerOrder}
                        onChange={ (e) => dispatch(updateEventStore("settings", { ...settings, maxItemsPerOrder: e.target.value })) }
                    />
                </FormControl>

                <Divider m="10px 0" />
                
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='hideEvent' mb='10px'>
                    Hide event
                    </FormLabel>
                    <Switch
                        id='hideEvent'
                        onChange={ (e) => dispatch(updateEventStore("settings", { ...settings, hideEvent: Number(e.target.checked) })) }
                        isChecked={Number(settings.hideEvent)} 

                    />
                </FormControl>

                <Divider m="10px 0" />

                <Box mt="20px">
                    <Button onClick={ () => dispatch(updateEvent(event)) }>Save</Button>
                </Box>
                
            </AccordionPanel>
        </AccordionItem>
    );
}

export default EventSettings;