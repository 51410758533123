import { connect, useSelector } from "react-redux";
import { useState } from "react";
import { Container } from "@chakra-ui/react";

import _ from "lodash";

import { createEvent, resetEvent } from "../../store/actions/eventsAction";

import Step1 from "../../components/events/create-event/Page1";
import Step2 from "../../components/events/create-event/Page2";
import Step3 from "../../components/events/create-event/Page3";

const CreateEvent = (props) => {
  const [step, setStep] = useState(1);
  const { event, organization } = useSelector((state) => state.events);
  const { user } = useSelector((state) => state.auth);

  const { createEvent, resetEvent } = props;

  const eventClone = _.cloneDeep(event);
  const organizationClone = _.cloneDeep(organization);
  const userClone = _.cloneDeep(user);

  const handleNext = () => { setStep(step + 1) };
  const handlePrev = () => { setStep(step - 1) };

  const handleFinish = () => {
    createEvent({ ...organizationClone, userId: userClone.id }, eventClone);
    resetEvent();
  };

  return (
    <Container maxWidth="500px">
      {step === 1 && <Step1 onNext={handleNext} />}
      {step === 2 && <Step2 onPrev={handlePrev} onNext={handleNext} />}
      {step === 3 && <Step3 onPrev={handlePrev} onFinish={handleFinish} />}
    </Container>
  );
};

export default connect(null, {
  createEvent,
  resetEvent
})(CreateEvent);
