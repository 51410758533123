import { Button, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Divider } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa6";


// actions
import { updateDrawer } from "../../../../store/actions/miscAction";
import { updateEvent } from "../../../../store/actions/eventsAction";

const TicketTypes = () => {
    const dispatch = useDispatch();
    const { event } = useSelector((state) => state.events);

    return (
        <AccordionItem overflow="visible">
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                    <Heading as="h3" size="md">Ticket Types</Heading>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} overflow="visible">
                <Menu>
                    <MenuButton as={Button} leftIcon={<FaPlus /> }>
                        Add New
                    </MenuButton>
                    <MenuList>
                        <MenuItem
                            onClick={() => dispatch( updateDrawer({ title: "Create Ticket Category", content: 'CreateTicketCategory', isOpen: 1 }) )}
                        >Add New Ticket Category</MenuItem>
                        <MenuItem
                            onClick={() => dispatch( updateDrawer({ title: "Create Ticket Type", content: 'CreateTicketType', isOpen: 1 }) )}
                        >Add New Type</MenuItem>
                    </MenuList>
                </Menu>
                <Divider m="10px 0" />
                123
                

                <Box mt="20px">
                    <Button onClick={ () => dispatch(updateEvent(event)) }>Save</Button>
                </Box>
                
            </AccordionPanel>
        </AccordionItem>
    );
}

export default TicketTypes;