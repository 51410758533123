import { Box, Button, HStack } from "@chakra-ui/react";
import FeesOverview from "./createEvent/FeesOverview";
import { NavLink } from "react-router-dom";

const Step2 = ({ onPrev, onFinish }) => {
    return (
        <Box>
            <Box mb="15px">
                <FeesOverview />
            </Box>
            <HStack spacing="15px">
                <Button onClick={onPrev}>Previous</Button>
                <NavLink to="/" onClick={onFinish}>
                    <Button>Finish</Button>
                </NavLink>
            </HStack>
        </Box>
    );
}

export default Step2;
