import {

} from '../actions/types';

const initialState = {
  drawer: {
    title: "",
    content: "",
    isOpen: 0,
  },
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {

    case 'UPDATE_DRAWER':
      return {
        ...state,
        drawer: {
          title: action.payload.title,
          content: action.payload.content,
          isOpen: action.payload.isOpen,
        }
      };

    case 'RESET_DRAWER':
        return {
          ...state,
          drawer: {
            title: "",
            content: "",
            isOpen: 0,
          }
        };
    
    default:
      return state;
  }
}