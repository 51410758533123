import { connect } from "react-redux";
import { NavLink } from "react-router-dom"; // Use the appropriate router package for your application
import { Box, Text, VStack } from "@chakra-ui/react";

import { signOut } from "../store/actions/authAction";

function Nav(props) {
  return (
    <Box className="navigation">
      <VStack spacing={4} align="stretch" pt={10}>
        <VStack spacing={2} align="stretch" pt={2}>
          <Text cursor="pointer" onClick={ () => props.signOut() }>
            Sign Out
          </Text>
          <Text fontSize="sm" fontWeight="bold" color="gray.500">
            Events
          </Text>
          <NavLink to="/" activeclassname="active">
            Dashboard
          </NavLink>
          <NavLink to="/organizations/" activeclassname="active">
            List Organizers
          </NavLink>
          <NavLink to="/events/all-events/" activeclassname="active">
            List Events
          </NavLink>
          <NavLink to="/events/add-organizer/" activeclassname="active">
            Add Organizer
          </NavLink>
          <NavLink to="/events/add-event/" activeclassname="active">
            Add Event
          </NavLink>
          <NavLink to="/events/buy-ticket/" activeclassname="active">
            Buy Ticket
          </NavLink>
        </VStack>
      </VStack>
    </Box>
  );
}

export default connect(null, {
  signOut,
})(Nav);
