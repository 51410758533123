import { connect, useSelector } from 'react-redux';
import { Box, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, FormControl, FormLabel, Heading, Input, Text, Textarea, HStack, Divider, Select } from "@chakra-ui/react";
import { listTimeZones } from "timezone-support";
import MultiSelect from 'react-select';
import dayjs from 'dayjs';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';


import { updateEventStore, updateEvent } from "../../../../store/actions/eventsAction";
import { IoTimeOutline } from 'react-icons/io5';
import DateTimePicker from 'react-datetime-picker';

// helpers 
import { formatTime } from '../../../../store/helpers';
import { categories, modules, formats } from '../../../../store/constants';



const BasicInformation = (props) => {
    const { event } = useSelector((state) => state.events);
    const { updateEventStore, updateEvent } = props;

    const timeZones = listTimeZones();

    const handleRemoveKey = (keyToRemove) => {
        const updatedInformation = { ...event.details.information };
        delete updatedInformation[keyToRemove];
        updateEventStore("details", { ...event.details, information: updatedInformation });
    };
      

    return (
        <AccordionItem>
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                    <Heading as="h3" size="md">Basic Information</Heading>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                <Box>
                    <FormControl mb="10px">
                        <Box>
                            <FormLabel>Event Title</FormLabel>
                            <Input type="text" value={event.details.name} onChange={ (e) => updateEventStore("details", { ...event.details, name: e.target.value }) } />
                            <Text fontSize="sm" color="gray.500" mt="5px">Your title should be consistent with social media profiles as search engines will use this to find your event. It will be displayed on the event page and on customers’ tickets.</Text>
                        </Box>
                    </FormControl>

                    <FormControl mb="10px">
                        <Box>
                            <FormLabel>Description</FormLabel>
                            <ReactQuill
                                theme="snow"
                                value={event.details.description}
                                onChange={ (e) => updateEventStore("details", { ...event.details, description: e } )}
                                modules={modules}
                                formats={formats}
                            />
                        </Box>
                    </FormControl>
                    
                    <Divider m="10px 0" />

                    <FormControl mb="10px">
                        <FormLabel>Start Date</FormLabel>
                        <DateTimePicker
                            onChange={(value) => updateEventStore("date", { ...event.date, start: dayjs(value).unix() }) }
                            value={new Date(dayjs.unix(event.date.start).format('YYYY-MM-DD HH:mm:ss')) }
                            disableClock={true}
                            minDate={new Date()}
                            format="dd-MM-y HH:mm"
                        />
                    </FormControl>

                    <FormControl mb="10px">
                        <FormLabel>End Date</FormLabel>
                        <DateTimePicker
                            onChange={(value) => updateEventStore("date", { ...event.date, end: dayjs(value).unix() }) }
                            value={new Date(dayjs.unix(event.date.end).format('YYYY-MM-DD HH:mm:ss')) }
                            disableClock={true}
                            minDate={new Date(dayjs.unix(event.date.start).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'))}
                            format="dd-MM-y HH:mm"
                        />
                    </FormControl>

                    

                    <FormControl mb="10px">
                        <FormLabel>Timezone</FormLabel>
                        <MultiSelect
                            options={timeZones.map((tz) => ({ value: tz, label: tz }))}
                            value={{ value: event.date.tz, label: event.date.tz }}
                            onChange={(value) => updateEventStore("date", { ...event.date, tz: value.value })}
                        />
                    </FormControl>

                    <Divider m="10px 0" />

                    <Box mb="15px" mt="15px">
                        <HStack spacing="6px">
                        <IoTimeOutline color="var(--blue)" size="20px" />
                        <>Event time: {formatTime(Number(event.date.end) -  Number(event.date.start))}</>
                        </HStack>
                    </Box>

                    <Divider m="10px 0" />

                    <FormControl mb="10px">
                        <FormLabel>Event Category</FormLabel>
                        <Select placeholder="Please select a Category" name="type" value={event.details.category.type} onChange={ (e) => updateEventStore("details", { ...event.details, category: { ...event.details.category, type: e.target.value } }) }>
                            {
                                categories.map((a, index) => ( <option key={index} value={a}>{a}</option> ))
                            }
                        </Select>
                    </FormControl>

                    <Divider m="10px 0" />

                    <FormControl mb="10px">
                        <FormLabel>Event URL</FormLabel>
                        <Input type="text" value={event.details.eventUrl} onChange={ (e) => updateEventStore("details", { ...event.details, eventUrl: e.target.value }) } />
                    </FormControl>

                    <Divider m="10px 0" />

                    <Box>
                        {
                            event.details.information.map((a, index) => (
                                <Box key={index}>
                                    <FormControl mb="10px">
                                        <Input type="text" value={a.name} onChange={ (e) => updateEventStore("details", { ...event.details, information: [ ...event.details.information.map((b, i) => i === index ? { ...event.details.information[index], name: e.target.value } : b),  ]} ) } />
                                        <ReactQuill
                                            theme="snow"
                                            value={a.value}
                                            onChange={ (e) => updateEventStore("details", { ...event.details, information: [ ...event.details.information.map((b, i )=> i === index ? { ...event.details.information[index], value: e } : b),  ]} ) }
                                            modules={modules}
                                            formats={formats}
                                        />
                                    </FormControl>
                                    <Text color="red" cursor="pointer" onClick={ (e) => updateEventStore("details", { ...event.details, information: [ ...event.details.information.filter((b, i) => i !==  index )  ]} ) }>Remove</Text>
                                </Box>
                            ))
                        }

                        <Button onClick={ () => updateEventStore("details", { ...event.details, information: [ ...event.details.information, { name: "Item " + Number(event.details.information.length + 1), value: "" } ] }) }>Add Additional Information</Button>
                    </Box>

                    <Box mt="20px">
                        <Button onClick={ () => updateEvent(event) }>Save</Button>
                    </Box>
                </Box>
            </AccordionPanel>
        </AccordionItem>
    );
}

export default connect(null, { updateEventStore, updateEvent })(BasicInformation);