import { Button, Divider, VStack } from "@chakra-ui/react";
import BasicOrganization from "./createEvent/BasicOrganization";
import BasicEvent from "./createEvent/BasicEvent";

const Step1 = ({ onNext }) => {
    return (
        <>
            <VStack spacing="5px" w="100%">
                <BasicOrganization />
                <Divider m="10px 0" />
                <BasicEvent />
            </VStack>
            <Button onClick={onNext}>Next</Button>
        </>
    );
}

export default Step1;
