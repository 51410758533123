import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import store from '../index';

const isDev = process.env.NODE_ENV === 'development';
const localAPI = 'http://localhost:4000';
const cloudAPI = 'https://api.retro.com.na';
const api  = isDev ? localAPI : cloudAPI;

export const signUp = (email, password, name, lastname) => async (dispatch) => {
    const endpoint = '/auth/';
    const payload = {
        email,
        password,
        name,
        lastname,
        created: dayjs().unix(),
    }
    const actionType = 'AUTHENTICATE_USER';
    try {
        const { data } = await axios.post(api +  endpoint, payload);
        toast.success('User created successfully');
        dispatch({ type: actionType, payload: data });        
    } catch (error) {
        toast.error('User already exists');        
    }
}

export const signIn = (email, password) => async (dispatch) => {
    const endpoint = '/auth/authenticate/';
    const actionType = 'AUTHENTICATE_USER';
    const payload = {
        email,
        password,
    }
    try {
      const { data } = await axios.post(api +  endpoint, payload);
      toast.success('User authenticated successfully');

      dispatch({ type: actionType, payload: data });

    } catch (error) {
        toast.error('Invalid credentials');        
    }
};

export const signOut = (refreshToken) => async (dispatch) => {
    // const endpoint = '/auth/unauthenticate/';
    const actionType = 'UNAUTHENTICATE_USER';
    dispatch({ type: actionType });
}

export const tokenStatusCheck = () => async (dispatch) => {
  const { accessToken } = store.getState().auth.user;
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  try {
    await axios.get(api +  '/auth/session-check/', { headers });
    dispatch({
      type: 'UPDATE_TOKEN_STATUS',
      payload: 1
    });
  } catch (err) {
    toast.warning("Your session has expired");
    
    dispatch({
      type: 'UPDATE_TOKEN_STATUS',
      payload: 0
    });
  }
};
