

export const updateDrawer = (options) => {
  return {
    type: 'UPDATE_DRAWER',
    payload: options,
  };
}

export const resetDrawer = () => {
  return {
    type: 'RESET_DRAWER',
  };
}