import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Accordion,
    Box,
    Heading,
} from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css';

import { setEvent, resetEvent } from "../../../store/actions/eventsAction";

import BasicInformation from './edit-event/BasicInformation';
import Appearance from './edit-event/Appearance';
import EventSettings from './edit-event/EventSettings';
import TicketTypes from './edit-event/TicketTypes';

const EditEvent = (props) => {
    const dispatch = useDispatch();
    const { events } = useSelector((state) => state.events);
    const { id } = useParams();

    useEffect(() => {
        return () => {
            dispatch(resetEvent());
        };
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            const data = events.find((e) => e.id === id);
            if (data) dispatch(setEvent(data));
        }
    }, [id, events, dispatch]);

    return (
        <Box>
            <Heading>Edit Event</Heading>
            <Box mt="20px">
                <Accordion>
                    <BasicInformation />
                    <Appearance />
                    <EventSettings />
                    <TicketTypes />
                </Accordion>
            </Box>
        </Box>
    );
};

export default EditEvent;
