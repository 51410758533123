import _ from 'lodash';
import {
  event, ticketCategory, ticketType, phasePricing, user, organization, ticketTransaction, promoCode
} from '../constants';

const initialState = {
  events: [],
  ticketCategories: [],
  ticketTypes: [],
  users: [],
  organizations: [],
  ticketTransactions: [],
  promoCodes: [],

  eventsFetchStatus: 0,
  ticketCategoriesFetchStatus: 0,
  ticketTypesFetchStatus: 0,
  usersFetchStatus: 0,
  organizationsFetchStatus: 0,
  ticketTransactionsFetchStatus: 0,
  promoCodesFetchStatus: 0,

  event: _.cloneDeep(event),
  ticketCategory: _.cloneDeep(ticketCategory),
  ticketType: _.cloneDeep(ticketType),
  user: _.cloneDeep(user),
  organization: _.cloneDeep(organization),
  ticketTransaction: _.cloneDeep(ticketTransaction),
  promoCode: _.cloneDeep(promoCode),

  uploads: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case 'GET_EVENTS':
      return {
        ...state,
        events: action.payload,
        eventsFetchStatus: 1,
      };

    case 'GET_TICKET_CATEGORIES':
      return {
        ...state,
        ticketCategories: action.payload,
        ticketCategoriesFetchStatus: 1,
      };

    case 'GET_TICKET_TYPES':
      return {
        ...state,
        ticketTypes: action.payload,
        ticketTypesFetchStatus: 1,
      };

    case 'GET_USERS':
      return {
        ...state,
        users: action.payload,
        usersFetchStatus: 1,
      };  

    case 'GET_ORGANIZATIONS':
      return {
        ...state,
        organizations: action.payload,
        organizationsFetchStatus: 1,
      };

    case 'GET_TICKET_TRANSACTIONS':
      return {
        ...state,
        ticketTransactions: action.payload,
        ticketTransactionsFetchStatus: 1,
      };

    case 'GET_PROMO_CODES':
      return {
        ...state,
        promoCodes: action.payload,
        promoCodesFetchStatus: 1,
      };

    case 'UPDATE_EVENT_STORE':
      return {
        ...state,
        event: {
          ...state.event,
          [action.payload.key]: action.payload.value
        }
      };
    
    case 'UPDATE_TICKET_CATEGORY_STORE':
      return {
        ...state,
        ticketCategory: {
          ...state.ticketCategory,
          [action.payload.key]: action.payload.value
        }
      };
    
    case 'UPDATE_PHASE_STORE':
      return {
        ...state,
        phase: {
          ...state.phase,
          [action.payload.key]: action.payload.value
        }
      };
    
    case 'UPDATE_PHASE_PRICING_STORE':
      return {
        ...state,
        phasePricing: {
          ...state.phasePricing,
          [action.payload.key]: action.payload.value
        }
      };

    case 'UPDATE_USER_STORE':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.value
        }
      };
    
    case 'UPDATE_ORGANIZATION_STORE':
      return {
        ...state,
        organization: {
          ...state.organization,
          [action.payload.key]: action.payload.value
        }
      };

    case 'UPDATE_TICKET_TRANSACTION_STORE':
      return {
        ...state,
        ticketTransaction: {
          ...state.ticketTransaction,
          [action.payload.key]: action.payload.value
        }
      };

    case 'UPDATE_PROMO_CODE_STORE':
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          [action.payload.key]: action.payload.value
        }
      };
      
    case 'CREATE_EVENT':
      return {
        ...state,
        events: [...state.events, action.payload.event].sort((a, b) => a.details.name.localeCompare(b.details.name)),
        event: _.deepClone(event),
      };

    case 'CREATE_ORGANIZATION':
      return {
        ...state,
        organizations: [...state.organizations, action.payload.organization].sort((a, b) => a.name.localeCompare(b.name)),
        organization: action.payload.organization,
      };

    case 'CREATE_TICKET_CATEGORY':
      return {
        ...state,
        ticketCategories: [...state.ticketCategories, action.payload.ticketCategory].sort((a, b) => a.name.localeCompare(b.name)),
        ticketCategory: _.deepClone(ticketCategory),
      };

    case 'CREATE_TICKET_TYPE':
      return {
        ...state,
        ticketTypes: [...state.ticketTypes, action.payload.ticketType].sort((a, b) => a.name.localeCompare(b.name)),
        ticketType: _.deepClone(ticketType),
      };      

    case 'UPDATE_EVENT':
      console.log(action.payload);
      return {
        ...state,
        events: state.events.map((e) => e.id === action.payload.id ? action.payload : e),
      };  
    case 'UPDATE_TICKET_TYPE':
      return {
        ...state,
        ticketTypes: state.ticketTypes.map((e) => e.id === action.payload.id ? action.payload : e),
      };
    case 'UPDATE_TICKET_CATEGORY':
      return {
        ...state,
        ticketCategories: state.ticketCategories.map((e) => e.id === action.payload.id ? action.payload : e),
      };
    case 'UPDATE_USER':
      return {
        ...state,
        users: state.users.map((e) => e.id === action.payload.id ? action.payload : e),
      };
    case 'UPDATE_ORGANIZATION':
      return {
        ...state,
        organizations: state.organizations.map((e) => e.id === action.payload.id ? action.payload : e),
      };
    case 'UPDATE_TICKET_TRANSACTION':
      return {
        ...state,
        ticketTransactions: state.ticketTransactions.map((e) => e.id === action.payload.id ? action.payload : e),
      };
    case 'UPDATE_PROMO_CODE':
      return {
        ...state,
        promoCodes: state.promoCodes.map((e) => e.id === action.payload.id ? action.payload : e),
      }; 

    case 'RESET_EVENT':
      return {
        ...state,
        event: _.cloneDeep(event),
      };
      case 'RESET_TICKET_TYPE':
      return {
        ...state,
        ticketType: _.cloneDeep(ticketType),
      };
      case 'RESET_TICKET_CATEGORY':
      return {
        ...state,
        ticketCategory: _.cloneDeep(ticketCategory),
      };
      case 'RESET_USER':
      return {
        ...state,
        user: _.cloneDeep(user),
      };
    case 'RESET_ORGANIZATION':
      return {
        ...state,
        organization: _.cloneDeep(organization),
      };
    case 'RESET_TICKET_TRANSACTION':
      return {
        ...state,
        ticketTransaction: _.cloneDeep(ticketTransaction),
      };
    case 'RESET_PROMO_CODE':
      return {
        ...state,
        promoCode: _.cloneDeep(promoCode),
      };

    case 'SET_EVENT':
      return {
        ...state,
        event: action.payload,
      };
    case 'SET_TICKET_TYPE':
      return {
        ...state,
        ticketType: action.payload,
      };
    case 'SET_TICKET_CATEGORY':
      return {
        ...state,
        ticketCategory: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_ORGANIZATION':
      return {
        ...state,
        organization: action.payload,
      };
    case 'SET_TICKET_TRANSACTION':
      return {
        ...state,
        ticketTransaction: action.payload,
      };
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCode: action.payload,
      };

      case 'DELETE_EVENT':
        return {
          ...state,
          events: state.events.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_TICKET_TYPE':
        return {
          ...state,
          ticketTypes: state.ticketTypes.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_TICKET_CATEGORY':
        return {
          ...state,
          ticketCategories: state.ticketCategories.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_USER':
        return {
          ...state,
          users: state.users.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_ORGANIZATION':
        return {
          ...state,
          organizations: state.organizations.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_TICKET_TRANSACTION':
        return {
          ...state,
          ticketTransactions: state.ticketTransactions.filter((a) => a.id !== action.payload),
        };
      case 'DELETE_PROMO_CODE':
        return {
          ...state,
          promoCodes: state.promoCodes.filter((a) => a.id !== action.payload),
        };

      case 'UPDATE_UPLOAD_PROGRESS':
        const uploads = _.cloneDeep(state.uploads);
        uploads[action.payload.name] = action.payload.progress;
        if( action.payload.progress == null ) {
          delete uploads[action.payload.name];
        }

        console.log(uploads);

        return {
          ...state,
          uploads
        }
    
    default:
      return state;
  }
}