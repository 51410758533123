import { Button, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Divider } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateEvent } from "../../../../store/actions/eventsAction";
// helpers
import { storageSettings } from "../../../../store/constants";
// components
import StorageUpload from "./appearance/StorageUpload";

const Appearance = () => {
    const dispatch = useDispatch();
    const { event } = useSelector((state) => state.events);

    const { appearance } = event;

    return (
        <AccordionItem overflow="visible">
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                    <Heading as="h3" size="md">Event Appearance</Heading>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} overflow="visible">
                {
                    storageSettings.map((a, i) => {
                        return <Box key={i}>
                            <StorageUpload a={a} />
                            { i !== appearance.length - 1 && <Divider p="10px 0" /> }
                        </Box>
                    })
                }

                <Box mt="20px">
                    <Button onClick={ () => dispatch(updateEvent(event)) }>Save</Button>
                </Box>
                
            </AccordionPanel>
        </AccordionItem>
    );
}

export default Appearance;