import { useDispatch, useSelector } from 'react-redux';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    HStack,
  } from '@chakra-ui/react'

//   actions
import { createTicketCategory, createTicketType } from '../../store/actions/eventsAction';
import { resetDrawer } from '../../store/actions/miscAction';
// components
import CreateTicketCategory from './content/CreateTicketCategory';
import CreateTicketType from './content/CreateTicketType';


function DrawerComponent(props) {
    const dispatch = useDispatch();

    const { ticketCategory, ticketType } = useSelector(state => state.events);
    const { drawer } = useSelector(state => state.misc);
    const { title, content, isOpen } = drawer;

    const components = {
      CreateTicketCategory: <CreateTicketCategory />,
      CreateTicketType: <CreateTicketType />,
    }

    const onClose = () => {
      dispatch(resetDrawer());
    };

    const submit = () => {
      console.log("Submit");
      if( content === 'CreateTicketCategory' ) {
        dispatch(createTicketCategory(ticketCategory));
      }
      else if( content === 'CreateTicketType' ) {
        dispatch(createTicketType(ticketType));
      }
    }
  
    return (  
      <Drawer onClose={onClose} isOpen={Boolean(isOpen)} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{title}</DrawerHeader>
          <DrawerBody>
            { components[content] }
          </DrawerBody>
          
          <DrawerFooter>
              <HStack justifyContent='center' width='full'>
                  <Button bg="var(--black)" color="#fff" onClick={() => submit()}>Save</Button>
                  <Button variant='outline' mr={3} onClick={onClose}>
                      Cancel
                  </Button>
              </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    )
}

export default DrawerComponent;