import { Box, Input, FormControl, FormLabel, VStack, Checkbox, Select, Divider, Text, HStack } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import { IoTimeOutline } from "react-icons/io5";


import { updateEventStore, updateOrganizationStore, createEvent } from "../../../../store/actions/eventsAction";
import { eventFrequencyList, ageDemographicList } from "../../../../store/constants";
import { formatTime } from "../../../../store/helpers";

const CreateEvent = (props) => {

  const { event } = useSelector((state) => state.events);

  const handleDateTimeUpdate = (key, value) => {
    const timestamp = dayjs(value).unix();
    const date = {
      ...event.date,
      [key]: timestamp
    };
    props.updateEventStore("date", date);
  };

  return (
    <>
        <FormControl mb="10px">
          <FormLabel>Start Date</FormLabel>
          <DateTimePicker
            onChange={(value) => handleDateTimeUpdate("start", value)}
            value={new Date(dayjs.unix(event.date.start).format('YYYY-MM-DD HH:mm:ss')) }
            disableClock={true}
            minDate={new Date()}
            format="dd-MM-y HH:mm"
          />
        </FormControl>
        <FormControl mb="10px">
          <FormLabel>End Date</FormLabel>
          <DateTimePicker
            onChange={(value) => handleDateTimeUpdate("end", value)}
            value={new Date(dayjs.unix(event.date.end).format('YYYY-MM-DD HH:mm:ss')) }
            disableClock={true}
            minDate={new Date(dayjs.unix(event.date.start).format('YYYY-MM-DD HH:mm:ss'))}
            format="dd-MM-y HH:mm"
          />
        </FormControl>

        <Box mb="15px" mt="15px">
          {/* add time icon from ionicons */}
          <Text>
            <HStack spacing="6px">
              <IoTimeOutline color="var(--blue)" size="20px" />
              <>Event time: {formatTime(Number(event.date.end) -  Number(event.date.start))}</>
            </HStack>
          </Text>
        </Box>

        <Divider m="10px 0" />

        <FormControl>
            <FormLabel>Target Age Demographic</FormLabel>
            <Select placeholder="Please select an option" name="ageDemographic" value={event.ageDemographic} onChange={ (e) => props.updateEventStore(e.target.name, e.target.value) }>
                {ageDemographicList.map((a, index) => (
                <option key={index} value={a}>{a}</option>
                ))}
            </Select>
        </FormControl>
        <FormControl>
            <FormLabel>Event Frequency</FormLabel>
            <Select placeholder="Please select an option" name="eventFrequency" value={event.eventFrequency} onChange={ (e) => props.updateEventStore(e.target.name, e.target.value) }>
                {eventFrequencyList.map((a, index) => (
                <option key={index} value={a}>{a}</option>
                ))}
            </Select>
        </FormControl>

    </>
  );
};

export default connect(null, {
  updateOrganizationStore,
  updateEventStore,
  createEvent,
})(CreateEvent);
