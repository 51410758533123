import {

} from '../actions/types';

const initialState = {
  user: {},
  isAuthenticated: 0,
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {

    case 'AUTHENTICATE_USER':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: 1,
      };   

    case 'UNAUTHENTICATE_USER':
      return {
        ...state,
        user: {},
        isAuthenticated: 0,
      };
    
    default:
      return state;
  }
}