import { Flex, Box } from "@chakra-ui/react";
import Nav from "../components/Nav";

import Drawer from '../components/misc/Drawer';

const Layout = (props) => {
  return (
    <>
    <Flex height="100vh">
      {/* Sidebar */}
      <Box
        width="250px"
        backgroundColor="gray.200"
        p={4}
        position="fixed"
        height="100%"
        overflowY="auto"  // Add this line for the scrollbar effect
        fontFamily="Geist Sans, sans-serif"  // Use Geist Sans font for the sidebar
      >
        <Box>
          <Nav />
        </Box>
      </Box>

      {/* Content Area */}
      <Box 
        marginLeft="250px" 
        flex="1"
        p={4}
        overflowY="auto"
        fontFamily="Geist Sans, sans-serif"  // Use Geist Sans font for the content area
       >
        {/* render children */}
        {/* do not render below on dashboard route */}
        {props.children}
      </Box>
    </Flex>
    <Drawer />
    </>
  );
};

export default Layout;
