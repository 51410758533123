import { Input, FormControl, FormLabel, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import { updateEventStore } from "../../../../store/actions/eventsAction";

const Currency = (props) => {

  const { event } = useSelector((state) => state.events);

  const { details } = event;
  const { basic } = details;
  const { price } = basic;

  return (
    <>
        <FormControl mb="10px">
          <FormLabel mb="5px">Currency</FormLabel>
          <Input type="text" value={price.currency} onChange={ (e) => props.updateEventStore("details", { ...event.details, basic: { ...basic, price: { ...price, currency:  e.target.value }}} ) } />
        </FormControl>

        <FormControl mb="10px">
          <FormLabel mb="5px">Average Ticket Price</FormLabel>
          <InputGroup>
            <InputLeftAddon>
              {price.symbol}
            </InputLeftAddon>
            <Input type="text" value={price.averageTicketPrice} onChange={ (e) => props.updateEventStore("details", { ...event.details, basic: { ...basic, price: { ...price, averageTicketPrice:  e.target.value }}}) } />
          </InputGroup>
        </FormControl>

    </>
  );
};

export default connect(null, {
  updateEventStore,
})(Currency);
