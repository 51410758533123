import { combineReducers } from 'redux';
import auth from './authReducer';
import events from './eventsReducer';
import misc from './miscReducer';


const reducer = combineReducers({
  auth,
  events,
  misc,
});

export default reducer