import React from 'react';
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux';

import { IoStorefrontOutline, IoCalendarOutline, IoPieChartOutline } from 'react-icons/io5'
import { NavLink } from 'react-router-dom';

function StatsCard({ title, stat, icon }) {

  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
};



export default function Dashboard() {
  // useselector
  const { organizations, events, organizationsFetchStatus, eventsFetchStatus } = useSelector((state) => state.events);
  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1 textAlign={'left'} fontSize={'4xl'} py={10} fontWeight={'bold'}>
        Dashboard
      </chakra.h1>
      {
        !Number(organizationsFetchStatus) || !Number(eventsFetchStatus)
        ? <Box>
            <Text>Loading your data...</Text>
          </Box>
        : <Box>
            <Box mb="20px">
                <Text>You have {events.filter(a=>Number(a.status)===1).length} active events.</Text>
                {
                  !events.length
                    ? organizations.length
                      ? <Text><NavLink to="/events/add-event/">Add an Event</NavLink> to get started.</Text>
                      : <Text><NavLink to="/events/add-organizer/">Add an Organizer</NavLink> to get started.</Text>
                    : <Text><NavLink to="/events/all-events/">View your Events</NavLink></Text>
                }
            </Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
              <StatsCard title={'Organizers'} stat={organizations.length} icon={<IoStorefrontOutline size={'3em'} />} />
              <StatsCard title={'Events'} stat={events.length} icon={<IoCalendarOutline size={'3em'} />} />
              <StatsCard title={'Tickets'} stat={'0'} icon={<IoPieChartOutline size={'3em'} />} />
            </SimpleGrid>
          </Box>
      }
    </Box>
  )
}