import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import { updateEventStore } from "../../../../store/actions/eventsAction";

const BasicOrganization = (props) => {
  const { organization, organizations } = useSelector((state) => state.events);
  const { user } = useSelector((state) => state.auth);
  return (
    <>
        <FormControl mb="10px">
          <FormLabel>Organizer</FormLabel>
          <Select placeholder="Select Organizer" name="organizationId" value={organization.id} onChange={ (e) => { props.updateEventStore(e.target.name, e.target.value); props.updateEventStore('userId', user.id);  } }>
            { organizations.map((a) => ( <option key={a.id} value={a.id}>{a.name}</option> )) }
          </Select>
        </FormControl>
    </>
  );
};

export default connect(null, {
  updateEventStore,
})(BasicOrganization);
