import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { updateTicketCategoryStore, setTicketCategory } from "../../../store/actions/eventsAction";

import { modules, formats } from '../../../store/constants';

const CreateTicketCategory = () => {
    const dispatch = useDispatch();
    const { event, ticketCategory, ticketCategories } = useSelector((state) => state.events);

    const { id } = useSelector((state) => state.misc.drawer);

    useEffect(() => {
        console.log("ID", event.id);
        if (id) {
            const data = ticketCategories.find((e) => e.id === id);
            if (data) dispatch(setTicketCategory(data));
        }
        else {
            dispatch(setTicketCategory({ ...ticketCategory, eventId: event.id }));
        }
    }, [dispatch]);

    return (
        <>
            <FormControl mb="20px">
                <FormLabel htmlFor='name' mb='10px'>
                    Name
                </FormLabel>
                <Input
                    value={ticketCategory.name}
                    onChange={(e) => dispatch(updateTicketCategoryStore("name", e.target.value))}
                />
            </FormControl>
            <FormControl mb="20px">
                <FormLabel htmlFor='description' mb='10px'>
                    Description
                </FormLabel>
                {/* use reactquill */}
                <ReactQuill
                    theme="snow"
                    value={ticketCategory.description}
                    onChange={(e) => dispatch(updateTicketCategoryStore("description", e ))}
                    modules={modules}
                    formats={formats}
                />
            </FormControl>

        </>

    );
}

export default CreateTicketCategory;